import { createContext, useState } from "react";
import { User } from "../interfaces/User.interface";

const emptyUser: User = {
    uid: "",
    name: "",
    email: "",
};

export const UserContext = createContext(emptyUser as any);

export const UserProvider = ({ children }: any) => {
    const [user, setUser] = useState(emptyUser as User);

    return <UserContext.Provider value={{user, setUser}}>{children}</UserContext.Provider>
};