import dayjs from "dayjs";

export const hideEmail = (email: string): string => {
    const [name, domain] = email.split("@");
    return `${name.substring(0, 2)}...@${domain}`;
};

export const i18n = (): any => {
    const lang: string = "pt";

    const locales: any = [
        {
            language: "pt",
            dateFormat: "DD/MM/YYYY HH:mm:ss",
            dateFormatDateOnly: "DD/MM/YYYY",
        }
    ];

    return locales.find((locale: any) => locale.language === lang);
};

export const isCPFValid = (cpf: string): boolean => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999") return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;
    return true;
};

export const randomString = (length: number = 10): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    };

    return result;
};

export const formatBytes = (bytes: any, decimals: any = 2): string => {
    if (!+bytes) return '0 Bytes';

    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i: any = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const convertFirebaseTimestampToString = (firebaseTimestamp: { _seconds: number, _nanoseconds: number }, format: string = "YYYY-MM-DD HH:mm:ss"): string => {
    const fireBaseTime = new Date(
        firebaseTimestamp._seconds * 1000 + firebaseTimestamp._nanoseconds / 1000000,
    );
    return dayjs(fireBaseTime).format(format);
}

export const JsonErrorToHtml = (props: any) => {
    const { formik }: any = props;
    return <>
        {!formik.valid && formik.errors.length > 0 && <>
            <div className="alert alert-danger mt-3 mb-3">
                <strong>Errors:</strong>
                <ul>
                    {Object.keys(formik.errors).map((key: any) => {
                        return <li key={`error-${key}`}><strong className="capitalize">{key}:</strong> {formik.errors[key]}</li>
                    })}
                </ul>
            </div>
        </>}
    </>
};