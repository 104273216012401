import axios from 'axios';
import { Schema } from '../interfaces/Schema.interface';

export const getSchemas = async (): Promise<Schema[]> => {
    return await axios.get('/schemas').then((r: any) => r.data).catch((e: any) => e);
};

export const createSchema = async (payload: Schema): Promise<any> => {
    return await axios.post('/schemas', payload).then((r: any) => r.data).catch((e: any) => e);
};

export const updateSchema = async (payload: Schema): Promise<any> => {
    return await axios.put(`/schemas/${payload.id}`, payload).then((r: any) => r.data).catch((e: any) => e);
};

export const testConnectionMySql = async (payload: Schema): Promise<any> => {
    return await axios.post('/schemas/testConnection/mysql', payload).then((r: any) => r.data).catch((e: any) => e);
};

export const deleteSchema = async (payload: {schemaId: string}): Promise<any> => {
    return await axios.delete(`/schemas/${payload.schemaId}`).then((r: any) => r.data).catch((e: any) => e);
};