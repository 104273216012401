import { useFormik } from 'formik';
import * as yup from 'yup';
import { JsonErrorToHtml } from '../../helpers';
import { updateSchema, testConnectionMySql } from '../../services/schemas.service';
import { Schema } from '../../interfaces/Schema.interface';
import Separator from '../Separator/Separator.component';
import { useContext, useState } from 'react';
import Loading from '../Loading/Loading.component';
import { AlertContext } from '../../context/Alert.context';
import { DataContext } from '../../context/Data.context';
import { Link } from 'react-router-dom';

const FormUpdateSchema = (props: any) => {
    const {
        onUpdateSchema,
        schema,
        isConnectedToDropbox,
    }: any = props;
    const { setAlert } = useContext(AlertContext);
    const { data } = useContext(DataContext);
    const [loadingUpdateSchema, setloadingUpdateSchema] = useState(false);

    const dropboxFolderName: string = process.env.REACT_APP_DROPBOX_FOLDER_NAME || "";
    const backupFunctionIP: string = process.env.REACT_APP_BACKUP_FUNCTION_IP || "";

    const initialValues: Schema = {
        description: '',
        environment: '',
        sendToDropbox: false,
        schedule: 'none',
        host: '',
        port: 3306,
        database: '',
        ...schema,
        user: '',
        password: '',
    };

    const formik: any = useFormik({
        initialValues: initialValues,
        isInitialValid: true,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: yup.object({
            description: yup.string().required('Required'),
            environment: yup.string().optional().oneOf(["development", "homologation", "production", ""]).default(""),
            sendToDropbox: yup.boolean().optional().default(false),
            schedule: yup.string().required('Required').oneOf(['default', 'none']),
            host: yup.string().required('Required').notOneOf(['localhost', '127.0.0.1', '0.0.0.0']),
            port: yup.number().required('Required').default(3306),
            database: yup.string().required('Required'),
            user: yup.string().optional().default(''),
            password: yup.string().optional().default(''),
        }),
        onSubmit: (values: any) => {
            setloadingUpdateSchema(true);
            updateSchema(values).then((response: any) => {
                if (response.message === "OK") {
                    onUpdateSchema(response.schema);
                    setloadingUpdateSchema(false);
                    setAlert({type: 'success', message: 'Schema atualizado com sucesso'});
                    return;
                }
                setDatabaseConnection(false);
                setloadingUpdateSchema(false);
                setAlert({type: 'danger', message: 'Erro ao atualizar o schema'});
            }).catch((error: any) => {
                console.log("ERROR", error);
                setDatabaseConnection(false);
                setloadingUpdateSchema(false);
                setAlert({type: 'danger', message: 'Erro ao atualizar o schema (errorNo: 2)'});
            });
        },
    });

    const [databaseConnection, setDatabaseConnection] = useState<boolean>(false);
    const [loadingTestConnection, setLoadingTestConnection] = useState<boolean>(false);

    const _testMySqlConnection = async () => {
        setLoadingTestConnection(true);
        await testConnectionMySql(formik.values).then((response: any) => {
            if (response.message === "OK") {
                setLoadingTestConnection(false);
                setDatabaseConnection(true);
                return;
            }
            setLoadingTestConnection(false);
            setDatabaseConnection(false);
        }).catch((error: any) => {
            console.log("ERROR", error);
            setLoadingTestConnection(false);
            setDatabaseConnection(false);
        });
    };

    return <>
        <form onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
        }}>

            <div className="row">
                <div className="col-12 col-md-6">
                    <strong>Configurações do Backup</strong>

                    <Separator size={10} />

                    <div className="form-group">
                        <label>Descrição</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Ex: Banco de dados do sistema XPTO'
                            maxLength={100}
                            name="description"
                            autoComplete='off'
                            disabled={loadingUpdateSchema}
                            onChange={formik.handleChange}
                            value={formik.values.description} />
                    </div>

                    <Separator size={10} />

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Ambiente</label>
                                <select
                                    className="form-control"
                                    name="environment"
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.environment}>
                                        <option value="">Outro</option>
                                        <option value="development">Desenvolvimento</option>
                                        <option value="homologation">Homologação</option>
                                        <option value="production">Produção</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <Separator size={10} />

                    <small className="text-muted">Esta informação é apenas para visualização dos dados e não vai interferir na realização dos backups.</small>

                    <Separator size={20} />

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Agendamento</label>
                                <select
                                    className="form-control"
                                    name="schedule"
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.schedule}>
                                        <option value="none">Nenhum</option>
                                        <option value="default">Padrão</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <Separator size={10} />

                    <small className="text-muted">Ao escolher "Padrão", o backup será executado automaticamente toda Segunda, Quarta e Sextas-feira às 03:00 AM (GMT).</small>

                    <Separator size={20} />

                    <div className="form-group">
                        <div className="form-check form-switch">
                            <label className="form-check-label me-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={formik.values.sendToDropbox}
                                    onChange={formik.handleChange}
                                    name="sendToDropbox"
                                    disabled={loadingUpdateSchema || !isConnectedToDropbox} />
                                Enviar os backups para o Dropbox 
                            </label>

                            {isConnectedToDropbox && <><small className='text-success'><i className="fa fa-check-circle me-2"></i> Conectado</small></>}
                            {!isConnectedToDropbox && <><small className='text-danger'><i className="fa fa-times-circle me-2"></i> Não está conectado</small></>}
                        </div>
                    </div>

                    <Separator size={10} />

                    {data.subscriptionPlan === "free" && <>
                        <small className="text-muted">
                            Para enviar os backups para o Dropbox, é necessário ter uma assinatura ativa. Clique para conferir os <Link to="/subscription">planos disponíveis</Link>
                        </small>
                    </>}

                    {formik.values.sendToDropbox && isConnectedToDropbox && data.subscriptionPlan !== "free" && <>
                        <small className="text-muted">
                            Os próximos backups serão enviados automaticamente para a pasta <strong>/Aplicativos/{dropboxFolderName}</strong> do seu Dropbox.
                        </small>
                    </>}
                </div>{/* /.col */}

                <div className="col-12 col-md-6">

                    <strong>Conexão com o banco de dados</strong>

                    <Separator size={10} />

                    <small className="text-muted">
                        Bancos de dados compatíveis:
                        <ul>
                            <li>MySQL <i className="fas fa-check-circle text-success ms-1"></i></li>
                            <li>MariaDB <i className="fas fa-check-circle text-success ms-1"></i></li>
                            <li>PostgreSQL (em breve)</li>
                            <li>Microsoft SQL Server (em breve)</li>
                            <li>Oracle (em breve)</li>
                        </ul>
                    </small>

                    <Separator size={10} />

                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="form-group">
                                <label>Host</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Ex: servidor1.database.com'
                                    name="host"
                                    autoComplete='off'
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.host} />
                            </div>

                            <Separator size={10} />

                            <small className="text-muted">
                                Hosts <strong>não permitidos</strong>: <i>localhost</i>, <i>127.0.0.1</i>, <i>0.0.0.0</i>.
                            </small>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label>Porta</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder='Ex: 3306'
                                    name="port"
                                    autoComplete='off'
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.port} />
                            </div>
                        </div>
                    </div>

                    <Separator size={10} />

                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="form-group">
                                <label>Banco de dados</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Ex: database1'
                                    name="database"
                                    autoComplete='off'
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.database} />
                            </div>
                        </div>
                    </div>

                    <Separator size={20} />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Usuário</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="user"
                                    placeholder='Ex: root'
                                    autoComplete='off'
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.user} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label>Senha</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    autoComplete='new-password'
                                    disabled={loadingUpdateSchema}
                                    onChange={formik.handleChange}
                                    value={formik.values.password} />
                            </div>
                        </div>
                    </div>

                    <Separator size={10} />

                    <small className="text-muted">
                        "Usuário" e "Senha" são salvos de forma segura criptografados em nosso banco de dados utilizando um algoritmo complexo de criptografia.<br />
                        Para não alterar os campos "Usuário" e "Senha", deixe-os em branco.
                    </small>

                    <Separator size={30} />

                    <div>
                        <button
                            disabled={!formik.isValid || loadingUpdateSchema || loadingTestConnection || formik.values.user === "" || formik.values.password === ""}
                            onClick={() => _testMySqlConnection()}
                            type="button"
                            className='btn btn-success'>
                                <Loading loading={loadingTestConnection} parent="inline" color="text-white" />
                                {!loadingTestConnection && <><i className="fas fa-database me-2"></i></>} Testar Conexão com o Banco de Dados
                        </button>

                        {formik.isValid && !loadingTestConnection && (formik.values.user !== "" || formik.values.password !== "") && <>
                            {databaseConnection && <i className="fas fa-check-circle ms-2 text-success"></i>}
                            {!databaseConnection && <i className="fas fa-times-circle ms-2 text-danger"></i>}
                        </>}
                    </div>

                    <Separator size={10} />

                    <small className="text-muted">
                        Para testar a conexão com o banco de dados, é necessário que o mesmo esteja acessível através da internet.
                        {/*
                        <br />
                        Você deve liberar o acesso ao banco de dados para o IP <strong>{backupFunctionIP}</strong>.
                        */}
                    </small>


                    <Separator size={10} />

                    <JsonErrorToHtml formik={formik} />

                    <Separator size={30} />

                    <button
                        type="submit"
                        disabled={!formik.isValid || loadingUpdateSchema || loadingTestConnection || (formik.values.user !== "" && formik.values.password !== "" && !databaseConnection)}
                        className="btn btn-primary">
                            <Loading loading={loadingUpdateSchema} parent="inline" color="text-white" />
                            {!loadingUpdateSchema && <><i className="fas fa-check-circle me-2"></i></>} Atualizar Schema
                    </button>

                    <Separator size={20} />
                </div>
            </div>
        </form>
    </>
};

export default FormUpdateSchema;