import { useContext, useEffect, useState } from "react";
import { getLogs } from "../../services/dashboard.service";
import { convertFirebaseTimestampToString, i18n } from "../../helpers";
import Loading from "../Loading/Loading.component";
import Separator from "../Separator/Separator.component";

import './Logs.component.scss';
import { AlertContext } from "../../context/Alert.context";
import { Link } from "react-router-dom";

const Logs = () => {
    useEffect(() => {
        _getLogs();
    }, []);

    const {setAlert} = useContext(AlertContext);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [successRate, setSuccessRate] = useState(0);
    const [logs, setLogs] = useState([]);

    const calculateSuccessRate = (l: any): number => {
        let totalError = 0;
        let totalSuccess = 0;
        l.forEach((log: any) => {
            if (log.action === 'create_backup_error') {
                totalError++;
            }
            if (['done_manual_backup_success', 'done_scheduled_backup_success'].includes(log.action)) {
                totalSuccess++;
            }
        });
        const total: number = totalError + totalSuccess;
        const successRate: number = (totalSuccess / total) * 100;
        return parseFloat(successRate.toFixed(2));
    }

    const _getLogs = async () => {
        setLoadingLogs(true);
        await getLogs().then((response: any) => {
            if (response.message === "OK") {
                setLogs(response.logs);
                setSuccessRate(calculateSuccessRate(response.logs));
                setLoadingLogs(false);
                return;
            }
            console.log('Error loading logs. Message received', response);
            setLoadingLogs(false);
        }).catch((error: any) => {
            console.log('Error loading logs', error);
            setAlert({type: 'danger', message: "Erro ao carregar os logs"});
            setLoadingLogs(false);
        });
    };

    const composeLogMessage = (log: any) => {
        let message = log.action;
        let icon = `fas fa-check-circle`;
        let color = `text-secondary`;

        switch (log.action) {
            case 'start_manual_backup_success':
                message = `Backup <strong>"${log.message}"</strong> iniciado`;
                icon = `fas fa-clock`;
                color = `text-secondary`;
                break;
            case 'start_scheduled_backup_success':
                message = `Agendamento de backup <strong>"${log.message}"</strong> iniciado`;
                icon = `fas fa-clock`;
                color = `text-secondary`;
                break;
            case 'done_manual_backup_success':
                message = `Backup <strong>"${log.message}"</strong> concluído`;
                icon = `fas fa-check-circle`;
                color = `text-success`;
                break;
            case 'done_scheduled_backup_success':
                message = `Agendamento de backup <strong>"${log.message}"</strong> concluído`;
                icon = `fas fa-clock`;
                color = `text-success`;
                break;
            case 'create_backup_error':
                message = `Erro ao realizar o backup <strong>"${log.message}"</strong>`;
                icon = `fas fa-exclamation-triangle`;
                color = `text-danger`;
                break;
            case 'download_backup_success':
                message = `Download do backup <strong>"${log.message}"</strong> realizado`;
                icon = `fas fa-file-download`;
                color = `text-success`;
                break;
            case 'create_schema_success':
                message = `Schema <strong>"${log.message}"</strong> criado`;
                icon = `fas fa-check-circle`;
                color = `text-success`;
                break;
            case 'create_schema_error':
                message = `Erro ao criar o schema <strong>"${log.message}"</strong>`;
                icon = `fas fa-exclamation-triangle`;
                color = `text-danger`;
                break;
            case 'upload_backup_to_dropbox_error':
                message = `Erro ao enviar o backup <strong>"${log.message}"</strong> para o Dropbox`;
                icon = `fab fa-dropbox`;
                color = `text-danger`;
                break;
            case 'upload_backup_to_dropbox_started':
                message = `Envio do backup <strong>"${log.message}"</strong> para o Dropbox iniciado`;
                icon = `fab fa-dropbox`;
                color = `text-secondary`;
                break;
            case 'upload_backup_to_dropbox_success':
                message = `Backup <strong>"${log.message}"</strong> enviado para o Dropbox`;
                icon = `fab fa-dropbox`;
                color = `text-success`;
                break;
            case 'retention_policy_backup_deleted_success':
                message = `Backup <strong>"${log.message}"</strong> Apagado de acordo com a Política de Retenção`;
                icon = `fas fa-trash`;
                color = `text-success`;
                break;
            case 'delete_schema_success':
                message = `Schema <strong>"${log.message}"</strong> apagado`;
                icon = `fas fa-check-circle`;
                color = `text-success`;
                break;
            case 'delete_schema_error':
                message = `Erro ao apagar o schema <strong>"${log.message}"</strong>`;
                icon = `fas fa-exclamation-triangle`;
                color = `text-danger`;
                break;
            case 'update_schema_success':
                message = `Schema <strong>"${log.message}"</strong> atualizado`;
                icon = `fas fa-check-circle`;
                color = `text-success`;
                break;
            case 'update_schema_error':
                message = `Erro ao atualizar o schema <strong>"${log.message}"</strong>`;
                icon = `fas fa-exclamation-triangle`;
                color = `text-danger`;
                break;
            default:
                message = log.message;
                break;
        }
        return {message, icon, color};
    };

    return <>
        <Loading loading={loadingLogs} />

        <div data-testid="logs">
            {!loadingLogs && logs.length === 0 && <>
                <div className="text-center">
                    <Separator size={30} />

                    <p>
                        <strong>Nenhum log encontrado</strong>
                    </p>
                    <p>
                        Os logs serão exibidos aqui sempre que um <br />backup for executado manualmente ou agendado.
                    </p>
                    <p>
                        <strong>Para começar, você precisa <Link to="/schemas" className="link-primary inline">&raquo; criar um schema &laquo;</Link></strong>
                    </p>
                </div>
            </>}

            {!loadingLogs && logs.length > 0 && <>
                <div className="row">
                    <div className="col">
                        <small className="text-muted">{logs.length} logs encontrados (listando os últimos 20)</small>
                    </div>
                    <div className="col text-center">
                        <small>{successRate}% taxa de sucesso de backup</small>
                    </div>
                    <div className="col text-end">
                        <button
                            disabled={loadingLogs}
                            onClick={() => _getLogs()}
                            className="btn btn-link btn-sm inline">
                                <Loading loading={loadingLogs} parent="inline" color="text-primary" />
                                {!loadingLogs && <i className="fas fa-sync me-2"></i>} Atualizar
                        </button>
                    </div>
                </div>

                <Separator size={10} />

                <Separator size={10} />

                <table className="table">
                    <tbody>
                        {logs.map((log: any, indexLog: number) => (
                            <tr key={`log-${indexLog}`} className={`log-${log.id}`}>
                                <td className="text-center">
                                    <i className={`${composeLogMessage(log).icon} ${composeLogMessage(log).color} me-2`}></i>
                                </td>
                                <td className="logMessage">
                                    <span dangerouslySetInnerHTML={{__html: composeLogMessage(log).message}}></span>
                                </td>
                                <td className="text-end">{convertFirebaseTimestampToString(log.createdAt, i18n().dateFormat)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>}
        </div>
    </>
};

export default Logs;