import { createContext, useState } from "react";

const emptyData = {
};

export const DataContext = createContext(emptyData as any);

export const DataProvider = ({ children }: any) => {
    const [data, setData] = useState(emptyData as any);

    return <DataContext.Provider value={{data, setData}}>{children}</DataContext.Provider>
};