import { useContext, useEffect, useState } from "react";
import { deleteSchema, getSchemas } from "../../services/schemas.service";
import { runManualBackup } from "../../services/backup.service";
import Loading from "../../components/Loading/Loading.component";
import FormCreateSchema from "../../components/FormCreateSchema/FormCreateSchema.component";
import { Schema } from "../../interfaces/Schema.interface";
import { convertFirebaseTimestampToString, i18n } from "../../helpers";
import Separator from "../../components/Separator/Separator.component";
import { Modal } from "react-bootstrap";
import { AlertContext } from "../../context/Alert.context";
import { ConfirmContext } from "../../context/Confirm.context";
import Expander from "../../components/Expander/Expander.component";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { getDropboxAccessToken } from "../../services/external.service";
import { DataContext } from "../../context/Data.context";
import FormUpdateSchema from "../../components/FormUpdateSchema/FormUpdateSchema.component";

const Schemas = () => {
    const {setAlert} = useContext(AlertContext);
    const {setConfirm} = useContext(ConfirmContext);
    const {data, setData} = useContext(DataContext);
    const [schemas, setSchemas] = useState([] as Schema[]);
    const [loadingSchemas, setLoadingSchemas] = useState(true);
    const [loadingRunManualBackup, setLoadingRunManualBackup] = useState(false);
    const [loadingManualSchemaId, setLoadingManualSchemaId] = useState<string | null>(null);

    useEffect(() => {
        _getSchemas();
        _getUrlParams();
    }, []);

    const _getSchemas = async () => {
        setLoadingSchemas(true);
        await getSchemas().then((response: any) => {
            if (response.message === "OK") {
                setIsConnectedToDropbox(data.isDropboxConnected);
                setSchemas(response.schemas);
                setLoadingSchemas(false);
                return;
            }
            console.log('Error loading schemas. Message received', response);
            setAlert({type: 'danger', message: 'Erro ao carregar os schemas'});
            setLoadingSchemas(false);
        }).catch((error: any) => {
            console.log('Error loading schemas', error);
            setAlert({type: 'danger', message: 'Error loading schemas'});
            setLoadingSchemas(false);
        });
    };

    const _runManualBackup = async (schema: any) => {
        setLoadingRunManualBackup(true);
        setLoadingManualSchemaId(schema.id);
        await runManualBackup({ schemaId: schema.id }).then((response: any) => {
            if (response.message === "OK") {
                setAlert({type: 'success', message: `O backup "${schema.description}" foi iniciado`});
                setLoadingRunManualBackup(false);
                setLoadingManualSchemaId(null);
                return;
            }
            console.log('Error running manual backup. Message received', response);
            setAlert({type: 'danger', message: 'Erro ao iniciar o backup manualmente'});
            setLoadingRunManualBackup(false);
            setLoadingManualSchemaId(null);
        }).catch((error: any) => {
            console.log('Error running manual backup', error);
            setAlert({type: 'danger', message: 'Erro ao iniciar o backup'});
            setLoadingRunManualBackup(false);
            setLoadingManualSchemaId(null);
        });
    };

    const [loadingDeleteSchema, setLoadingDeleteSchema] = useState(false);
    const [loadingDeleteSchemaId, setLoadingDeleteSchemaId] = useState<string | null>(null);

    const _deleteSchema = async (schemaId: string) => {
        setLoadingDeleteSchema(true);
        setLoadingDeleteSchemaId(schemaId);
        await deleteSchema({ schemaId }).then((response: any) => {
            if (response.message === "OK") {
                const newSchemas: any[] = schemas.filter((schema: Schema) => schema.id !== schemaId);
                setSchemas(newSchemas);
                setLoadingDeleteSchema(false);
                setLoadingDeleteSchemaId(null);
                setAlert({type: 'success', message: 'Schema apagado'});
                return;
            }
            console.log('Error running manual backup. Message received', response);
            setLoadingDeleteSchema(false);
            setLoadingDeleteSchemaId(null);
        }).catch((error: any) => {
            console.log('Error running manual backup', error);
            setAlert({type: 'danger', message: 'Erro ao apagar o schema'});
            setLoadingDeleteSchema(false);
            setLoadingDeleteSchemaId(null);
        });
    };

    const [showModalUpdateSchema, setShowModalUpdateSchema] = useState(false);

    const [showModalCreateSchema, setShowModalCreateSchema] = useState(false);

    const getScheduleHumanReadable = (schedule: string) => {
        let scheduleHumanReadable = "";
        switch (schedule) {
            case "none":
                scheduleHumanReadable = "Nenhum Agendamento";
                break;
            case "default":
                scheduleHumanReadable = "Segunda, Quarta e Sexta-feira, às 03:00 AM";
                break;
        }
        return scheduleHumanReadable;
    };

    const [isConnectedToDropbox, setIsConnectedToDropbox] = useState(false);

    const _getUrlParams = async () => {
        setLoadingConnectToDropbox(true);

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (!code) {
            setLoadingConnectToDropbox(false);
            return;
        }

        await getDropboxAccessToken(code).then(() => {
            setAlert({type: 'success', message: 'Conectado com sucesso ao Dropbox'});
            setLoadingConnectToDropbox(false);

            // Remove code from url to prevent user from refreshing the page losing the access token
            window.history.replaceState({}, document.title, "/" + "schemas");

            setData({
                ...data,
                isDropboxConnected: true,
            });

            setIsConnectedToDropbox(true);
        }).catch((error: any) => {
            console.log("ERROR ACCESS TOKEN", error);
            setAlert({type: 'danger', message: 'Erro ao se conectar com o Dropbox'});
            setLoadingConnectToDropbox(false);
        });
    };

    const [loadingConnectToDropbox, setLoadingConnectToDropbox] = useState(false);

    const _connectToDropbox = async () => {
        setLoadingConnectToDropbox(true);
        const clientId: string = process.env.REACT_APP_DROPBOX_CLIENT_ID || "";
        const redirectUri: string = process.env.REACT_APP_DROPBOX_REDIRECT_URI || "";
        window.location.href = `https://www.dropbox.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&token_access_type=offline`;
    };

    const [selectedSchemaToEdit, setSelectedSchemaToEdit] = useState({} as Schema);

    return (
        <div className="Schemas">
            <PageHeader>
                <div className="row">
                    <div className="col-6">
                        <h1>Schemas</h1>
                    </div>
                    <div className="col-6 text-end">
                        <button
                            data-testid="schemas-button-create"
                            disabled={loadingSchemas || loadingRunManualBackup || loadingDeleteSchema}
                            className="btn btn-primary" onClick={() => setShowModalCreateSchema(true)}>
                            <i className="fas fa-plus me-2"></i> Criar Schema
                        </button>
                    </div>
                </div>
                <p>
                    Schemas são configurações de conexão com o banco de dados.<br />
                    Gerencie cada schema e seus agendamentos automáticos.
                </p>

                <button
                    type="button"
                    data-testid="schemas-button-connect-to-dropbox"
                    disabled={loadingConnectToDropbox}
                    className="btn btn-outline-primary"
                    onClick={() => _connectToDropbox()}>
                        <Loading loading={loadingConnectToDropbox} parent="inline" color="text-primary" />
                        {!loadingConnectToDropbox && <><i className="fab fa-dropbox me-2"></i></>} Conectar com Dropbox
                </button>

                {data.isDropboxConnected && <>
                    <small className="text-success">
                        <i className="fas fa-check-circle ms-2 me-2"></i> conectado
                    </small>
                </>}
            </PageHeader>

            <Loading loading={loadingSchemas} />

            <div className="container" data-testid="schemas">
                {!loadingSchemas && schemas.length === 0 && <>
                    <div className="text-center">
                        <Separator size={30} />

                        <p>
                            <strong>Nenhum schema encontrado</strong><br />
                            Crie um schema para começar a fazer backups
                        </p>

                        <Separator size={30} />

                        <button
                            disabled={loadingSchemas || loadingRunManualBackup || loadingDeleteSchema}
                            className="btn btn-primary" onClick={() => setShowModalCreateSchema(true)}>
                            <i className="fas fa-plus me-2"></i> Criar o Primeiro Schema
                        </button>
                    </div>
                </>}

                {!loadingSchemas && schemas.length > 0 && <>
                    <div data-testid="schemas-list">
                        <small className="text-muted">{schemas.length} schemas</small>

                        <Separator size={10} />

                        {schemas.map((schema: Schema | any) => (
                            <div key={`card-schema-${schema.id}`} className="data-testid-schema-item">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="float-end">
                                            <div className="dropdown">
                                                <button
                                                    disabled={
                                                        (loadingRunManualBackup && loadingManualSchemaId === schema.id) ||
                                                        (loadingDeleteSchema && loadingDeleteSchemaId === schema.id)}
                                                    className="btn btn-secondary dropdown-toggle data-testid-schemas-button-actions"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                        <Loading loading={(loadingRunManualBackup && loadingManualSchemaId === schema.id) || (loadingDeleteSchema && loadingDeleteSchemaId === schema.id)} parent="inline" color="text-white" />
                                                        {(!loadingRunManualBackup || loadingManualSchemaId !== schema.id) && 
                                                        (!loadingDeleteSchema || loadingDeleteSchemaId !== schema.id) && <><i className="fas fa-cog me-2"></i></>} Ações
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button
                                                            disabled={
                                                                (loadingRunManualBackup && loadingManualSchemaId === schema.id) ||
                                                                (loadingDeleteSchema && loadingDeleteSchemaId === schema.id)}
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedSchemaToEdit(schema);
                                                                setShowModalUpdateSchema(true);
                                                            }}>
                                                                <Loading loading={loadingRunManualBackup && loadingManualSchemaId === schema.id} parent="inline" color="text-primary" />
                                                                {loadingManualSchemaId !== schema.id && <><i className="fas fa-edit me-2"></i></>}
                                                                Editar schema
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            disabled={
                                                                (loadingRunManualBackup && loadingManualSchemaId === schema.id) ||
                                                                (loadingDeleteSchema && loadingDeleteSchemaId === schema.id)}
                                                            className="dropdown-item"
                                                            onClick={() => 
                                                                setConfirm({
                                                                    type: "primary",
                                                                    title: "Iniciar Backup Manual",
                                                                    message: `Tem certeza que deseja executar um backup do schema "<strong>${schema.description}</strong>"?`,
                                                                    buttonLabel: "Iniciar Backup",
                                                                    onConfirm: () => _runManualBackup(schema),
                                                                })}>
                                                                <Loading loading={loadingRunManualBackup && loadingManualSchemaId === schema.id} parent="inline" color="text-primary" />
                                                                {loadingManualSchemaId !== schema.id && <><i className="fas fa-sync me-2"></i></>}
                                                                Iniciar Backup Manual
                                                        </button>
                                                    </li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li>
                                                        <button
                                                            disabled={
                                                                (loadingDeleteSchema && loadingDeleteSchemaId === schema.id) || 
                                                                (loadingRunManualBackup && loadingManualSchemaId === schema.id)}
                                                            className="dropdown-item text-danger"
                                                            onClick={() => 
                                                                setConfirm({
                                                                    type: "danger",
                                                                    title: "Apagar Schema",
                                                                    message: `Tem certeza que deseja apagar o schema "<strong>${schema.description}</strong>"?`,
                                                                    messageLine2: `Esta ação não poderá ser desfeita.`,
                                                                    buttonLabel: "Apagar Schema",
                                                                    onConfirm: () => _deleteSchema(schema.id),
                                                                })}>
                                                                <Loading loading={loadingDeleteSchema && loadingDeleteSchemaId === schema.id} parent="inline" color="text-danger" />
                                                                {loadingDeleteSchemaId !== schema.id && <><i className="fas fa-trash me-2"></i></>}
                                                                Remover Schema
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            {schema.description}

                                            {schema.environment === "development" && <div className="badge bg-secondary ms-2"><i className="fas fa-database me-1"></i> desenvolvimento</div>}
                                            {schema.environment === "homologation" && <div className="badge bg-secondary ms-2"><i className="fas fa-database me-1"></i> homologação</div>}
                                            {schema.environment === "production" && <div className="badge bg-primary ms-2"><i className="fas fa-database me-1"></i> produção</div>}
                                        </div>

                                        <small className="text-muted">
                                            Schema ID: {schema.id.substring(0, 10)} | Criado em: {convertFirebaseTimestampToString(schema.createdAt, i18n().dateFormat)}
                                            {schema.schedule !== "none" && <>
                                                <br />
                                                <i className="fas fa-clock me-2"></i> {getScheduleHumanReadable(schema.schedule)}
                                            </>}
                                            {isConnectedToDropbox && schema.sendToDropbox === true && <>
                                                <br />
                                                <i className="fab fa-dropbox me-2"></i> Os backups serão enviados para o Dropbox
                                            </>}
                                        </small>

                                        <Separator size={5} />

                                        <Expander contentId={schema.id} labelExpand="Mostrar mais" labelCollapse="Mostrar menos">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Host</th>
                                                        <th>Porta</th>
                                                        <th>Banco de Dados</th>
                                                        <th>Notificar para</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{schema.host}</td>
                                                        <td>{schema.port}</td>
                                                        <td>{schema.database}</td>
                                                        <td>{schema.notificationEmail}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Expander>

                                    </div>
                                </div>

                                <Separator size={10} />
                            </div>
                        ))}
                    </div>
                </>}
            </div>

            {/* Modal CREATE schema */}

            <Modal show={showModalCreateSchema} size="xl">
                <Modal.Header closeButton onClick={() => setShowModalCreateSchema(false)}>
                    <Modal.Title>Criar Schema</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FormCreateSchema onCreateSchema={(e: Schema) => {
                        const newSchema: Schema = e;
                        const newSchemasOrdered: any[] = [...schemas, newSchema].sort((a, b) => (a.description > b.description) ? 1 : -1);
                        setSchemas(newSchemasOrdered);
                        setShowModalCreateSchema(false);
                    }}
                    isConnectedToDropbox={isConnectedToDropbox} />

                </Modal.Body>
            </Modal>


            {/* Modal UPDATE schema */}

            <Modal show={showModalUpdateSchema} size="xl">
                <Modal.Header closeButton onClick={() => setShowModalUpdateSchema(false)}>
                    <Modal.Title>Update Schema</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FormUpdateSchema onUpdateSchema={(e: Schema) => {
                        const updatedSchema: Schema = e;
                        const schemaIndex: number = schemas.findIndex((schema: Schema) => schema.id === updatedSchema.id);
                        schemas[schemaIndex] = updatedSchema;
                        const updatedSchemasOrdered: any[] = [...schemas].sort((a, b) => (a.description > b.description) ? 1 : -1);
                        setSchemas(updatedSchemasOrdered);
                        setShowModalUpdateSchema(false);
                    }}
                    schema={selectedSchemaToEdit}
                    isConnectedToDropbox={isConnectedToDropbox} />

                </Modal.Body>
            </Modal>

        </div>
    );
};

export default Schemas;