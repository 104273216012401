import { useContext } from "react";
import { UserContext } from "../../context/User.context";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { logout } from "../../services/auth.service";
import { User } from "../../interfaces/User.interface";
import { DataContext } from "../../context/Data.context";

import "./Navbar.component.scss";

const CustomLink = (props: any) => {
    const { to, children } = props;
    let resolved: any = useResolvedPath(to);
    let match: any = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link data-testid={`navbar-${to.replace('/','')}`} className={`nav-link ${match ? "active" : ""}`} to={to}>
            {children}
        </Link>
    );
};

const Navbar = () => {
    const { user, setUser } = useContext(UserContext);
    const { data } = useContext(DataContext);
    const navigate: any = useNavigate();

    const callLogout = async () => {
        await logout();

        const emptyUser: User = {
            uid: "",
            name: "",
            email: "",
        };

        setUser(emptyUser);
        navigate("/login");
        return;
    };

    return <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary pt-4">
            <div className="container">
                <Link className="navbar-brand" to={ user.uid !== "" ? "/panel" : "/login" }>
                    <img src="/images/carbon.svg" alt="" className="logo img-fluid" style={{ maxWidth: "250px" }} />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {user.uid !== "" && data.subscriptionPlan === "pro" && <>
                            <li className="nav-item">
                                <div className="badge bg-primary ms-2">pro</div>
                            </li>
                        </>}
                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {user.uid !== "" && <>
                            <li className="nav-item">
                                <CustomLink to="/panel">
                                    <i className="fas fa-tachometer-alt me-2"></i> Dashboard
                                </CustomLink>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/schemas">
                                    <i className="fas fa-database me-2"></i> Schemas
                                </CustomLink>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/backups">
                                    <i className="fas fa-stream me-2"></i> Backups
                                </CustomLink>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/subscription">
                                    <i className="fas fa-credit-card me-2"></i> Assinatura
                                </CustomLink>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/faq">
                                    <i className="fas fa-question-circle me-2"></i> Dúvidas
                                </CustomLink>
                            </li>
                            <li className="nav-item">
                                <button type="button" data-testid="navbar-logout" className="nav-link" onClick={() => callLogout()}><i className="fas fa-sign-out-alt me-2"></i> Sair</button>
                            </li>
                        </>}

                        {user.uid === "" && <>
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">Login</Link>
                            </li>
                        </>}
                    </ul>
                </div>
            </div>
        </nav>

        <div className="bg-light">
            <div className="container pt-3">
                <small className="text-muted">
                    Olá <span className="fw-bold">{user.name}</span> ({user.email})
                </small>
            </div>
        </div>
    </>
};

export default Navbar;