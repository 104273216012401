import { useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { UserContext } from "../../context/User.context";
import { AlertContext } from "../../context/Alert.context";
import { DataContext } from "../../context/Data.context";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../interfaces/User.interface";
import { useFormik } from "formik";
import * as yup from 'yup';
import { getProfile } from "../../services/profiles.service";
import { signJWT } from "../../services/jwt.service";
import Alert from "../../components/Alert/Alert.component";
import Separator from "../../components/Separator/Separator.component";
import Loading from "../../components/Loading/Loading.component";
import { logout } from "../../services/auth.service";

import "./Login.view.scss";

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const Login = () => {
  const {user, setUser} = useContext(UserContext);
  const {alert, setAlert} = useContext(AlertContext);
  const {data, setData} = useContext(DataContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    const doLogout = async (): Promise<any> => {
      await logout();

      const emptyUser: User = {
        uid: "",
        name: "",
        email: "",
      };

      setUser(emptyUser);
      navigate("/login");
      return;
    };
    doLogout();
  }, []);

  const emailPasswordInitialValues: any = {
    email: "",
    password: "",
  };

  const [loadingAuthentication, setLoadingAuthentication] = useState(false);

  const formikEmailPassword: any = useFormik({
    initialValues: emailPasswordInitialValues,
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
    }),
    onSubmit: (values: any) => {
      const auth: any = getAuth();
      setLoadingAuthentication(true);
      signInWithEmailAndPassword(auth, values.email, values.password).then(async (result: any) => {
        const {uid, email}: User = result.user;

        // Need to set the JWT before getProfile() because it needs the JWT to authenticate
        const firstJWTSigned: any = await signJWT({uid, email} as User);
        localStorage.setItem("access_token", firstJWTSigned);

        await getProfile().then(async (responseProfile: any) => {
          if (responseProfile.message && responseProfile.message === "OK") {
            delete responseProfile.message;
            const newData: any = {...data, ...responseProfile, gateway: "pagarme"};
            localStorage.setItem("data", JSON.stringify(newData));
            setData(newData);

            const newUser: any = {uid, name: responseProfile.name, email};
            localStorage.setItem("user", JSON.stringify(newUser as User));
            setUser(newUser);

            // Need to set the JWT again because now we have the name
            const updatedJWTSigned: any = await signJWT({uid, name: responseProfile.name, email} as User);
            localStorage.setItem("access_token", updatedJWTSigned);

            setLoadingAuthentication(false);
            navigate("/panel");
            return;
          }
        }).catch((e: any) => {
          setAlert({type: "danger", message: "Erro ao carregar dados do perfil."});
          setLoadingAuthentication(false);
        });
      }).catch((error: any) => {
        setLoadingAuthentication(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        const errors: any[] = [{
          "code": "auth/user-not-found",
          "message": "Usuário não encontrado.",
        }, {
          "code": "auth/wrong-password",
          "message": "Senha incorreta.",
        }];
        setAlert({type: "danger", message: errors.find((e: any) => e.code === errorCode)?.message || errorMessage});
        console.log('Error Google SignIn:', errorCode, errorMessage);
      });

    },
  });

  return <>
    <Alert alert={alert} />

    <div className="Login container">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="d-flex" style={{ height: "100vh", flexDirection: "column", justifyContent: "center" }}>


            <div className="card shadow border-0">
              <div className="card-body">

                <Separator size={40} />

                <div className="text-center">
                  <img src="/images/carbon.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
                </div>

                <Separator size={40} />

                {user.uid === "" && <>
                  <div className="text-center">
                    <p>
                        Faça o login para acessar.<br />
                        <strong>Não é cadastrado?</strong> <Link to="/signup" data-testid="login-signup" className="link-primary inline">Crie uma conta</Link>
                    </p>

                    <Separator size={20} />

                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10">

                        <form onSubmit={(e: any) => {
                          e.preventDefault();
                          formikEmailPassword.handleSubmit();
                        }}>
                          <div className="form-group mb-3">
                            <input
                              type="email"
                              disabled={loadingAuthentication}
                              name="email"
                              data-testid="login-email"
                              autoComplete="off"
                              onChange={formikEmailPassword.handleChange}
                              value={formikEmailPassword.values.email}
                              className="form-control"
                              placeholder="Seu e-mail" />
                          </div>

                          <div className="form-group mb-3">
                            <input
                              type="password"
                              name="password"
                              data-testid="login-password"
                              autoComplete="off"
                              onChange={formikEmailPassword.handleChange}
                              value={formikEmailPassword.values.password}
                              disabled={loadingAuthentication}
                              className="form-control"
                              placeholder="Senha" />
                          </div>

                          <div className="d-grid">
                            <button
                              type="submit"
                              data-testid="login-submit"
                              disabled={loadingAuthentication || !formikEmailPassword.isValid}
                              className="btn btn-primary btn-block">
                                <Loading loading={loadingAuthentication} parent="inline" color="text-white" />
                                {!loadingAuthentication && <i className="fas fa-sign-in-alt me-2"></i>} Entrar
                            </button>
                          </div>

                        </form>

                      </div>
                    </div>

                    <Separator size={40} />

                    <Link to="/passwordRecover" data-testid="login-recover-password" className="link-primary inline">Esqueci minha senha</Link>

                    {/*
                    <button
                      disabled={false || loadingAuthentication}
                      className="btn btn-outline-primary btn-google-signin"
                      type="button" onClick={() => authenticate_GoogleSignin()}
                      >
                        <img src="images/g-logo.png" alt="Sign in with Google" className="img-fluid" /> Entrar com Google
                    </button>
                    */}
                  </div>
                </>}

              </div>
            </div>{/* /.card */}

          </div>

        </div>
      </div>
    </div>
  </>
};

export default Login;