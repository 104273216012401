import { useContext, useEffect, useState } from "react";
import { getStats } from "../../services/dashboard.service";
import Loading from "../Loading/Loading.component";
import { AlertContext } from "../../context/Alert.context";

const Stats = () => {
    useEffect(() => {
        _getStats();
    }, []);

    const {setAlert} = useContext(AlertContext);
    const [loadingStats, setLoadingStats] = useState(true);
    const [stats, setStats] = useState({} as { schemas: number, backups: number });

    const _getStats = async () => {
        setLoadingStats(true);
        await getStats().then((response: any) => {
            if (response.message === "OK") {
                setStats(response);
                setLoadingStats(false);
                return;
            }
            console.log('Error loading stats. Message received', response);
            setLoadingStats(false);
        }).catch((error: any) => {
            setAlert({type: 'danger', message: "Erro ao carregar as estatísticas"});
            console.log('Error loading stats', error);
            setLoadingStats(false);
        });
    };

    return <>
        <div className="row">
            <div className="col-12 col-md">
                <div className="card border-0 shadow-sm text-center">
                    <div className="card-body">
                        <Loading loading={loadingStats} />
                        {!loadingStats && <><h2><strong>{stats.schemas}</strong></h2></>}
                        Schemas
                    </div>
                </div>
            </div>
            <div className="col-12 col-md">
                <div className="card border-0 shadow-sm text-center">
                    <div className="card-body">
                        <Loading loading={loadingStats} />
                        {!loadingStats && <><h2><strong>{stats.backups}</strong></h2></>}
                        Backups
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Stats;
