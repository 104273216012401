import PageHeader from "../../components/PageHeader/PageHeader.component"
import Separator from "../../components/Separator/Separator.component";

const Faq = () => {

    const faq: any[] = [
        {
            question: "O que é um Schema?",
            answer: "Um Schema é, basicamente, as configurações de um banco de dados. Por exemplo, se você tem um banco de dados MySQL, você precisa informar o endereço do servidor, usuário, senha, nome do banco de dados, etc. Essas informações são o Schema. A plataforma permite que você crie vários Schemas, para vários bancos de dados diferentes.",
        },
        {
            question: "Para que serve o Teste de Conexão com o banco de dados?",
            answer: "O Teste de Conexão serve para verificar se as informações do Schema estão corretas. Caso alguma informação esteja incorreta, você não conseguirá fazer o backup do banco de dados.",
        },
        {
            question: "O que é a tag de ambiente ao criar um Schema?",
            answer: 'A tag de ambiente é uma informação que você pode adicionar ao Schema para identificar o ambiente do banco de dados. Por exemplo, se você tem um banco de dados de produção e um banco de dados de desenvolvimento, você pode adicionar a tag de ambiente "produção" e "desenvolvimento" para identificar cada um deles. A tag de ambiente não interfere no backup, é apenas uma informação para você identificar o banco de dados.',
        },
        {
            question: 'O que é a "Nota" no backup?',
            answer: "É apenas um campo para você adicionar alguma informação sobre o backup. Por exemplo, se você fez um backup manual, você pode adicionar uma nota para identificar o motivo daquele backup.",
        },
        {
            question: "O que é a Política de Retenção?",
            answer: "A Política de Retenção é o tempo que os backups ficam salvos na plataforma. Atualmente, a Política de Retenção está definida para 30 dias. Após este período, os backups serão automaticamente excluídos. Os backups que foram enviados para o Dropbox não são afetados pela Política de Retenção.",
        },
        {
            question: "Como funciona o plano gratuito?",
            answer: "O plano gratuito permite que você utilize todos os recursos da plataforma. A única limitação é que não é possível fazer o download dos backups ou enviá-los para o Dropbox.",
        },
        {
            question: "Como é feito o backup automático?",
            answer: 'Ao criar um schema e selecionar a opção de backup automático, a plataforma irá fazer um backup de forma automática às segundas, quartas e sextas-feiras, entre 04:00h e 08:00h da manhã. Caso a opção "Enviar para o Dropbox" esteja selecionada, o backup será enviado para o Dropbox também de forma automática.',
        },
        {
            question: "Como é feito o backup manual?",
            answer: "Você pode fazer um backup manual à qualquer momento. O backup manual garante a sua segurança em situações antes de você fazer alguma alteração no seu sistema ou banco de dados. Se acontecer algum problema, seu backup estará salvo.",
        },
        {
            question: "Como funciona a integração com o Dropbox?",
            answer: "Ao conectar sua conta do Dropbox, você pode enviar seus backups para lá. Isso garante que seus backups estarão salvos em um outro lugar, além da nossa plataforma. Caso você cancele sua assinatura, seus backups continuarão salvos no seu Dropbox. A nossa Política de Retenção também não exlui os backups que estão no seu Dropbox.",
        },
        {
            question: "Como é feita a cobrança?",
            answer: "A cobrança é feita de forma automática, a cada 30 dias. Caso você cancele sua assinatura, a cobrança será cancelada, você não será mais cobrado e não terá mais acesso aos recursos do plano PRO. Não há multas ou taxas de cancelamento.",
        },
        {
            question: "Como é feito o pagamento?",
            answer: "O pagamento é feito por cartão de crédito e processado pela empresa Pagar.me, uma das maiores empresas de pagamentos do Brasil. Nós não armazenamos os dados do seu cartão de crédito. Apenas a Pagar.me tem acesso aos seus dados. A cada ciclo de cobrança, o seu pagamento é automaticamente processado pela Pagar.me.",
        },
        {
            question: "Quais são os bancos de dados aceitos?",
            answer: "Por enquanto a plataforma aceita apenas bancos de dados MySQL e MariaDB. Em breve iremos adicionar suporte para outros bancos de dados.",
        },

        {
            question: "Precisa de ajuda?",
            answer: 'Se você tiver alguma dúvida, entre em contato conosco através do e-mail <span class="text-primary">suporte@backupcarbon.com</span>. Nossa equipe está pronta para te ajudar.',
        },
    ];

    return (
        <div>
            <PageHeader>
                <h1>Ajuda</h1>
                <p>
                    Veja as perguntas e respostas mais frequentes que podem te ajudar.
                </p>
            </PageHeader>

            <div className="container" data-testid="faq">
                <div className="accordion" id="accordionExample">
                    {faq.map((item, index) => {
                        return <div key={`faq-item-${index}`} className="accordion-item data-testid-faq-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                {item.question}
                            </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <span dangerouslySetInnerHTML={{__html:item.answer}}></span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    );
};

export default Faq;