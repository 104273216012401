import { Link, useNavigate } from "react-router-dom";
import Separator from "../../components/Separator/Separator.component";
import { useContext, useState } from "react";
import * as yup from 'yup';
import {
    getAuth,
    sendPasswordResetEmail,
} from "firebase/auth";
import { useFormik } from "formik";
import { AlertContext } from "../../context/Alert.context";
import Loading from "../../components/Loading/Loading.component";
import Alert from "../../components/Alert/Alert.component";

const PasswordRecover = () => {
  const navigate: any = useNavigate();

  const initialValues: any = {
    email: "",
  };

  const {alert, setAlert} = useContext(AlertContext);
  const [loadingRecover, setLoadingRecover] = useState(false);

  const formikPasswordRecover: any = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: (values: any) => {
      setLoadingRecover(true);
      sendPasswordResetEmail(getAuth(), values.email).then(() => {
        setAlert({
            type: "success",
            message: "Um e-mail foi enviado para você com as instruções para recuperar a senha.",
        });
        setLoadingRecover(false);
        formikPasswordRecover.resetForm();
        navigate("/");
      }).catch((error: any) => {
        console.log("Error on send password recover email", error);
        setLoadingRecover(false);
        setAlert({
            type: "danger",
            message: "Não foi possível enviar o e-mail de recuperação de senha. Por favor, tente novamente.",
        });
      });
    },
  });

  return <>
    <Alert alert={alert} />

    <div className="Signup container">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

            <div className="d-flex" style={{ height: "100vh", flexDirection: "column", justifyContent: "center" }}>

                <div className="card shadow border-0">
                    <div className="card-body">

                      <Separator size={40} />

                      <div className="text-center">
                        <img src="/images/carbon.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
                      </div>

                      <Separator size={40} />

                      <div className="text-center">
                        <p>
                          <strong>Esqueceu sua senha?</strong><br />Recupere-a informando o email cadastrado.
                        </p>

                        <Separator size={5} />

                        <p>
                          <Link to="/" data-testid="password-recover-back" className="link-primary inline">&laquo; Voltar para o Login</Link>
                        </p>

                        <Separator size={20} />

                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">

                                <form onSubmit={(e: any) => {
                                    e.preventDefault();
                                    formikPasswordRecover.handleSubmit();
                                }}>
                                    <div className="form-group mb-3">
                                        <input
                                            type="email"
                                            name="email"
                                            data-testid="password-recover-email"
                                            autoComplete="off"
                                            disabled={loadingRecover}
                                            onChange={formikPasswordRecover.handleChange}
                                            value={formikPasswordRecover.values.email}
                                            className="form-control"
                                            placeholder="Seu e-mail" />
                                    </div>

                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            data-testid="password-recover-submit"
                                            disabled={loadingRecover || !formikPasswordRecover.isValid}
                                            className="btn btn-primary btn-block">
                                                <Loading loading={loadingRecover} parent="inline" color="text-white" />
                                                {!loadingRecover && <i className="fas fa-check-circle me-2"></i>} Recuperar Senha
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>

                      </div>

                      <Separator size={20} />
                    </div>
                </div>{/* /.card */}

            </div>

        </div>
      </div>
    </div>
  </>
};

export default PasswordRecover;