import axios from 'axios';

export const getBackups = async (): Promise<any> => {
    return await axios.get('/backups').then((r: any) => r.data).catch((e: any) => e);
};

export const runManualBackup = async (payload: {schemaId: string}): Promise<any> => {
    return await axios.post('/backups/runManualBackup', payload).then((r: any) => r.data).catch((e: any) => e);
};

export const retryBackup = async (payload: {backupId: string}): Promise<any> => {
    return await axios.post('/backups/retryBackup', payload).then((r: any) => r.data).catch((e: any) => e);
};

export const updateBackupNotes = async (payload: {backupId: string, notes: string}): Promise<any> => {
    return await axios.post('/backups/updateBackupNotes', payload).then((r: any) => r.data).catch((e: any) => e);
};

export const getBackupFileUrl = async (backupId: string): Promise<any> => {
    return await axios.get(`backups/getFileDownloadUrl/${backupId}`).then((r: any) => r.data).catch((e: any) => e);
};