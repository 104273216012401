import { createContext, useState } from "react";

interface Alert {
    type: string
    message: string
}

const emptyAlert: Alert = {
    type: "",
    message: "",
};

export const AlertContext = createContext(emptyAlert as any);

export const AlertProvider = ({ children }: any) => {
    const [alert, setAlert] = useState(emptyAlert as Alert);

    return <AlertContext.Provider value={{alert, setAlert}}>{children}</AlertContext.Provider>
};