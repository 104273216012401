import { getAuth } from "firebase/auth";

export const logout = async (): Promise<any> => {
    await getAuth().signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("data");
      localStorage.removeItem("access_token");
      return;
    }).catch((error: any) => {
      console.log("Error on logout", error);
    });
};