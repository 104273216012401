import PageHeader from "../../components/PageHeader/PageHeader.component";
import Separator from "../../components/Separator/Separator.component";

const Terms = () => {
    return (
        <div className="Terms">
            <PageHeader>
                <h1>Termos de Uso, Políticas de Segurança e Privacidade</h1>
                <p>
                    Leia com atenção as políticas de segurança e privacidade antes de utilizar o sistema.<br />
                    O documento à seguir pode ser alterado sem aviso prévio, portanto, verifique-o periodicamente.
                </p>
            </PageHeader>

            <Separator size={40} />

            <div className="container">

                <strong>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</strong>

                <p>
                    Estes Termos de Uso ("Termos") e a Política de Privacidade ("Política") regem o uso da 
                    plataforma CARBON ("o Serviço") fornecido por Criarti - Sistemas e Aplicativos Ltda. 
                    ("nós", "nosso" ou "nos"). Ao acessar ou usar o Serviço, você concorda com estes 
                    Termos e a Política. Se você não concordar com estes Termos ou a Política, não use o Serviço.
                </p>

                <p>
                    ---
                </p>

                <p>
                    <strong>TERMOS DE USO</strong>
                    </p>

                    <p>
                    <strong>1. Uso do Serviço</strong>
                    </p>

                    <p>
                    1.1 <strong>Contas:</strong> Para acessar alguns recursos do Serviço, você pode ser 
                    solicitado a criar uma conta. Ao criar uma conta, você concorda em fornecer informações 
                    precisas, completas e atualizadas. Você é responsável por manter a confidencialidade de sua conta e senha.
                </p>

                <p>
                    1.2 <strong>Uso Aceitável:</strong> Você concorda em usar o Serviço apenas para os 
                    fins propostos e de acordo com estes Termos. Você concorda em não usar o Serviço de maneira 
                    que possa prejudicar, desabilitar, sobrecarregar ou comprometer nossos sistemas ou segurança, 
                    sob pena de responsabilidade civil e criminal.
                </p>

                <p>
                    <strong>2. Propriedade Intelectual</strong>
                </p>

                <p>
                    2.1 <strong>Direitos Autorais:</strong> Todos os recursos e funcionalidades disponíveis no 
                    Serviço são protegidos por direitos autorais, marcas registradas e outras leis de propriedade intelectual.
                </p>

                <p>
                    2.2 <strong>Direitos Autorais:</strong> Todas as informações geradas (backups) pelo Serviço 
                    são de propriedade e responsabilidade exclusiva do usuário. Os conteúdos dos backups não são acessados 
                    ou validados por nós, exceto quando necessário para fornecer o Serviço ou conforme exigido por lei.
                </p>

                <p>
                    2.3 <strong>Licença:</strong> Concedemos a você uma licença limitada, não exclusiva e 
                    intransferível para acessar e usar o Serviço para os fins propostos.
                </p>

                <p>
                    <strong>3. Isenção de Responsabilidade</strong>
                </p>

                <p>
                    3.1 <strong>Uso por Sua Conta e Risco:</strong> O uso do Serviço é por sua conta e risco. 
                    O Serviço é fornecido "no estado em que se encontra", sem garantias de qualquer tipo.
                </p>

                <p>
                    3.2 <strong>Limitação de Responsabilidade:</strong> Em nenhuma circunstância seremos 
                    responsáveis por danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso do Serviço.
                </p>

                <p>
                    <strong>4. Alterações nos Termos</strong>
                </p>

                <p>
                    Reservamos o direito de modificar estes Termos a qualquer momento. As alterações entrarão em 
                    vigor imediatamente após serem postadas no Serviço. O seu uso contínuo do Serviço após tais 
                    alterações constituirá seu reconhecimento e aceitação dos Termos modificados.
                </p>

                <p>
                    <strong>5. Lei Aplicável</strong>
                </p>

                <p>
                    Estes Termos são regidos e interpretados de acordo com as leis do Brasil, 
                    independentemente de suas disposições de conflito de leis.
                </p>

                <p>
                    <strong>6. Politica de Retenção de Dados</strong>
                </p>

                <p>
                    6.1 <strong>Retenção de Dados:</strong> Os dados gerados (backups) são acessíveis por até 5 ou 30 dias dependendo do plano contratado. 
                    Após o período de retenção correspondete ao plano contrato, os dados (backups) são excluídos automaticamente da conta do usuário 
                    sem aviso prévio ou meios de recuperação.
                </p>

                <p>
                    6.2 <strong>Retenção de Dados Para Fins Legais:</strong> Os dados gerados (backups) podem ser retidos por mais tempo para fins legais, 
                    por exemplo, se forem objeto de uma solicitação de retenção legal. Neste caso, os dados (backups) serão retidos até que a solicitação seja atendida.
                </p>

                <p>
                    ---
                </p>

                <p>
                    <strong>POLÍTICA DE PRIVACIDADE</strong>
                </p>

                <p>
                    <strong>1. Uso das Informações</strong>
                </p>

                <p>
                    1.1 <strong>Propósito:</strong> Utilizamos as informações coletadas para fornecer, manter, 
                    proteger e melhorar o Serviço, além de desenvolver novos serviços.
                </p>

                <p>
                    1.2 <strong>Não Compartilhamento com Terceiros:</strong> Não compartilhamos suas informações 
                    pessoais com terceiros, exceto se exigido por lei.
                </p>

                <p>
                    <strong>2. Segurança das Informações</strong>
                </p>

                <p>
                    2.1 <strong>Proteção:</strong> Empregamos medidas de segurança para proteger suas informações 
                    contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.
                </p>

                <p>
                    2.2 <strong>Responsabilidade do Usuário:</strong> Embora tomemos medidas para proteger suas informações, 
                    você é responsável por manter a confidencialidade de sua senha e informações de conta, e por controlar o acesso à sua conta.
                </p>

                <p>
                    <strong>3. Cookies e Tecnologias Semelhantes</strong>
                </p>

                <p>
                    3.1 <strong>Cookies:</strong> Podemos usar cookies e tecnologias semelhantes para melhorar a experiência do 
                    usuário e coletar informações sobre o uso do Serviço.
                </p>

                <p>
                    <strong>4. Alterações nesta Política</strong>
                </p>

                <p>
                    Reservamos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento. 
                    Alterações entrarão em vigor imediatamente após serem postadas no Serviço.
                </p>

                <p>
                    <strong>5. Consentimento</strong>
                </p>

                <p>
                    Ao usar nosso Serviço, você consente com os termos desta Política de Privacidade.
                </p>

                <p>
                    <strong>
                        Lembramos que, embora nos esforcemos para proteger suas informações, a segurança completa não pode ser garantida. 
                        É importante que você tome medidas para garantir a segurança de suas informações, como a proteção da senha e o 
                        acesso não autorizado à plataforma.
                    </strong>
                </p>

                <Separator size={70} />

                <small className="text-muted">
                    Última atualização: 15/11/2023
                </small>

                <Separator size={70} />
            </div>
        </div>
    );
};

export default Terms;