import { Link, useNavigate } from "react-router-dom";
import Separator from "../../components/Separator/Separator.component";
import { useContext, useState } from "react";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useFormik } from "formik";
import Loading from "../../components/Loading/Loading.component";
import { User } from "../../interfaces/User.interface";
import { signJWT } from "../../services/jwt.service";
import { createProfile } from "../../services/profiles.service";
import { AlertContext } from "../../context/Alert.context";
import * as yup from 'yup';
import {
  getAuth,
  createUserWithEmailAndPassword
} from "firebase/auth";

import "./Signup.view.scss";

const Signup = () => {
  const navigate: any = useNavigate();
  const {setAlert} = useContext(AlertContext);
  const [passwordScore, setPasswordScore] = useState(0);

  const initialValues: any = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [loadingSignup, setLoadingSignup] = useState(false);

  const formikSignup: any = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup.string().required().min(3),
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
      confirmPassword: yup.string().min(6).required().oneOf([yup.ref("password")], "As senhas não conferem.")
    }),
    onSubmit: (values: any) => {
      const auth: any = getAuth();
      setLoadingSignup(true);
      createUserWithEmailAndPassword(auth, values.email, values.password).then(async (result: any) => {
        const {uid, email}: User = result.user;
        const signed: any = await signJWT({uid, name: values.name, email} as User);
        localStorage.setItem("access_token", signed);
        await createProfile({name: values.name}).then((r: any) => r).catch((e: any) => e);
        setLoadingSignup(false);
        navigate("/login");
        setAlert({type: "success", message: "Conta criada com sucesso!"});
      }).catch((error: any) => {
        setLoadingSignup(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error Google SignIn:', errorCode, errorMessage);
      });
    },
  });

  /*
  const authenticate_GoogleSignin = (): void => {
    const auth: any = getAuth();
    setLoadingAuthentication(true);
    signInWithPopup(auth, provider).then(async (result: any) => {
      const {uid, displayName, email, photoURL}: User = result.user;
      const signed: any = await signJWT({uid, displayName, email, photoURL} as User);

      localStorage.setItem("user", JSON.stringify({uid, displayName, email, photoURL} as User));
      localStorage.setItem("access_token", signed);

      setUser({uid, displayName, email, photoURL});
      setLoadingAuthentication(false);
      navigate("/panel");
    }).catch((error: any) => {
      setLoadingAuthentication(false);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error Google SignIn:', errorCode, errorMessage);
    });
  };
  */

  return <>
    <div className="Signup container">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="d-flex" style={{ height: "100vh", flexDirection: "column", justifyContent: "center" }}>

            <div className="card shadow border-0">
              <div className="card-body">

                <Separator size={40} />

                <div className="text-center">
                  <img src="/images/carbon.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
                </div>

                <Separator size={40} />

                <div className="text-center">
                  <p>
                      Crie sua conta gratuita na Carbon.<br />
                      <strong>Já é cadastrado?</strong> <Link to="/" data-testid="signup-back" className="link-primary inline">Ir para o Login &raquo;</Link>
                  </p>

                  <Separator size={20} />

                  <div className="row justify-content-center">
                    <div className="col-12 col-md-10">

                      <form onSubmit={(e: any) => {
                        e.preventDefault();
                        formikSignup.handleSubmit();
                      }}>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            name="name"
                            data-testid="signup-name"
                            autoComplete="off"
                            onChange={formikSignup.handleChange}
                            value={formikSignup.values.name}
                            disabled={loadingSignup}
                            className="form-control"
                            placeholder="Seu nome completo" />
                        </div>

                        <div className="form-group mb-3">
                          <input
                            type="email"
                            name="email"
                            data-testid="signup-email"
                            autoComplete="off"
                            onChange={formikSignup.handleChange}
                            value={formikSignup.values.email}
                            disabled={loadingSignup}
                            className="form-control"
                            placeholder="Seu e-mail" />
                        </div>

                        <div className="form-group mb-3">
                          <input
                            type="password"
                            name="password"
                            data-testid="signup-password"
                            autoComplete="new-password"
                            onChange={formikSignup.handleChange}
                            value={formikSignup.values.password}
                            disabled={loadingSignup}
                            className="form-control"
                            placeholder="Senha" />
                        </div>

                        <PasswordStrengthBar
                          password={formikSignup.values.password}
                          onChangeScore={setPasswordScore}
                          shortScoreWord="muito fraca"
                          scoreWords={['muito fraca', 'fraca', 'normal', 'boa', 'forte']} />

                        <Separator size={10} />

                        <div className="text-start">
                          <small className="text-muted">
                            A senha deve ter no mínimo 6 caracteres, letras maiúsculas, minúsculas, números e caracteres especiais.
                          </small>
                        </div>

                        <Separator size={20} />

                        <div className="form-group mb-3">
                          <input
                            type="password"
                            autoComplete="off"
                            name="confirmPassword"
                            data-testid="signup-password-confirm"
                            onChange={formikSignup.handleChange}
                            value={formikSignup.values.confirmPassword}
                            disabled={loadingSignup}
                            className="form-control"
                            placeholder="Confirmar Senha" />
                        </div>

                        <div className="d-grid">
                          <button
                            type="submit"
                            data-testid="signup-submit"
                            disabled={loadingSignup || !formikSignup.isValid || passwordScore < 3}
                            className="btn btn-primary btn-block">
                              <Loading loading={loadingSignup} parent="inline" color="text-white" />
                              {!loadingSignup && <i className="fas fa-check-circle me-2"></i>} Criar Conta
                          </button>
                        </div>
                      </form>

                    </div>
                  </div>

                  <Separator size={40} />

                  <small>
                    <span className="text-muted">
                        Ao se cadastrar, você declara ter lido e concorda com os <Link to="/terms" data-testid="signup-terms" className="link-primary inline" target="_blank">Termos de Uso</Link> desta plataforma.
                    </span>
                  </small>
                </div>

              </div>
            </div>{/* /.card */}

          </div>

        </div>
      </div>
    </div>
  </>
};

export default Signup;